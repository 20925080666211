
import Api from "@/services/Api";
import { defineComponent, Ref, ref, onMounted, watch } from "vue";
import type { PropType } from "vue";
import useAlert from '@/composables/Alert'
import useEmitter from "@/composables/Emmiter";



export default defineComponent({
  name: "ModalDialogo",
  components: {
  },
  props: {
    titulo: String,
    subtitulo: String,
    mensagem: String,
  },

  setup(props) {


   
    return {
    };
  },
});
