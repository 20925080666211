
import Api from "@/services/Api";
import { defineComponent, Ref, ref, onMounted, watch } from "vue";
import type { PropType, pushScopeId, toValue } from "vue";
import useAlert from '@/composables/Alert'
import useEmitter from "@/composables/Emmiter";
import ModalDialogo from "@/components/ModalDialogo.vue"
import { Modal } from "bootstrap";


export default defineComponent({
  name: "ModalGrupo",
  components: {
    ModalDialogo,
  },
  props: {
    grupo: Object as PropType<any>,
    fechaModal: Function,
    abreModal: Function,
  },

  setup(props) {

    const { showTimeAlert } = useAlert()
    const emitter = useEmitter()

    const modalDialogo: Ref<any> = ref()

    const loadSalva = ref(false);
    const loadExclui = ref(false);

    const tentouSalvar = ref(false);

    const grupo: Ref<any> = ref({});

    const arrayTipoOferta: Ref<any[]> = ref([]);
    const arrayLayoutApp: Ref<any[]> = ref([]);
    const arrayChecklistAdicional: Ref<any[]> = ref([]);



    function fechaModal(){

      grupo.value = {};


      props.fechaModal && props.fechaModal()

    }

    function abreModal(){

      grupo.value = { ...props.grupo } 

      // conversão necessaria pois o el-switch não reconhece o 1 ou 0 do tipo number
      grupo.value.buscaPlaca = grupo.value.buscaPlaca == 1 ? 'S' : 'N';
      grupo.value.usaChecklist = grupo.value.usaChecklist == 1 ? 'S' : 'N';

      if(!grupo.value.tipoOferta) grupo.value.tipoOferta = 0;
      if(!grupo.value.tipoApp) grupo.value.tipoApp = 0;
      if(!grupo.value.checklistAdicional) grupo.value.checklistAdicional = 0;

      tentouSalvar.value = false;

      console.log('grupo', grupo.value)
    
    }




    function atualizaGrupo(grupo: any) {
      emitter.emit('atualizaGrupo', { grupo: grupo })
    }

    function removeGrupo(grupo: any) {
      emitter.emit('removeGrupo', { grupo: grupo })
    }



    //     FUNÇÕES PRINCIPAIS     //
    // 
    //      ||            ||      //
    //      ||            ||      //
    //      ||            ||      //
    //      ||            ||      //
    //     \  /          \  /     //
    //      \/            \/      //

    async function salvaGrupo() {

      try {

        tentouSalvar.value = true;

        if(!grupo.value.nome || !grupo.value.responsavel) return showTimeAlert('campos obrigatórios estão vazios', "error");
        
        loadSalva.value = true;

        // conversão necessaria pois o el-switch não reconhece o 1 ou 0 do tipo number
        grupo.value.buscaPlaca = grupo.value.buscaPlaca == 'S' ? 1 : 0;
        grupo.value.usaChecklist = grupo.value.usaChecklist == 'S' ? 1 : 0;
  
        const { data } = await Api.post("salvaGrupo", { grupo: grupo.value });
  
        loadSalva.value = false;

        if(!data.grupo) return showTimeAlert(data?.message, "error");

        grupo.value = data.grupo

        showTimeAlert("grupo salvo com sucesso!");

        atualizaGrupo(data.grupo)

        fechaModal()
  
      } catch (error) { loadSalva.value = false; }

    }



    async function deletaGrupo() {

      try {
        
        loadExclui.value = true;

        const { data } = await Api.delete("deletaGrupo", { codGrupo: grupo.value.codGrupo });

        loadExclui.value = false;

        if(!data.grupo) return showTimeAlert(data?.message, "error");

        showTimeAlert("grupo excluido com sucesso!");

        removeGrupo(data.grupo)

        fechaModal()

      } catch (error) { loadExclui.value = false; }

    }





    


    // abrindo modal com usuario
    watch(() => props.grupo, async () => { abreModal() }, { deep: true })

    onMounted(async()=>{ 

      try{
        
        modalDialogo.value = new Modal(document.querySelector(`#ModalDialogo`));

        arrayTipoOferta.value = (await Api.get('getTipoOferta') as any).data.tipos || [];
        arrayLayoutApp.value = (await Api.get('getLayoutApp') as any).data.layouts || [];
        arrayChecklistAdicional.value = (await Api.get('getChecklist', { tipo: 'adicional'}) as any).data.checklists || [];

        arrayTipoOferta.value.unshift({id: 0, nome: 'Sem tipo oferta'})
        arrayLayoutApp.value.unshift({codBandeira: 0, descricao: 'Sem layout'})
        arrayChecklistAdicional.value.unshift({checklist: 0, nome: 'Sem checklist'})


      } catch(error) { console.error(error) }

    });
   
    return {
      console,
      arrayTipoOferta,
      arrayLayoutApp,
      arrayChecklistAdicional,
      grupo,
      fechaModal,
      salvaGrupo,
      deletaGrupo,
      loadSalva,
      loadExclui,
      tentouSalvar,
      modalDialogo,
    };
  },
});
