import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { id: "modais" }
const _hoisted_2 = {
  class: "modal fade modal-md",
  id: "ModalDialogo",
  tabindex: "-1",
  role: "dialog",
  "aria-labelledby": "exampleModalCenterTitle",
  "aria-hidden": "true"
}
const _hoisted_3 = {
  class: "modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg",
  role: "document"
}
const _hoisted_4 = {
  class: "modal-content",
  style: {"border-radius":"15px"}
}
const _hoisted_5 = { class: "modal-header py-3 px-5" }
const _hoisted_6 = { class: "d-flex gap-4 p-0 align-items-center" }
const _hoisted_7 = { class: "m-0 subtitulo cor_texto_1" }
const _hoisted_8 = {
  key: 0,
  class: "m-0 legenda text-muted"
}
const _hoisted_9 = {
  class: "modal-body background_2 py-3 px-5",
  id: "modalBody"
}
const _hoisted_10 = { class: "m-0 texto bold cor_texto_1" }
const _hoisted_11 = { class: "modal-footer d-flex flex-nowrap justify-content-end align-items-center py-3 px-5 gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.titulo), 1),
                (_ctx.subtitulo)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.subtitulo), 1))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.mensagem), 1)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ])
      ])
    ])
  ]))
}